import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';




const Page404 = React.lazy(() => import("./pages/Page404"));
const NavbarComponent = React.lazy(() => import("./components/NavbarComponent/NavbarComponent"));
const LandingPage = React.lazy(() => import("./pages/LandingPage"));
const ContactUsPage = React.lazy(() => import("./pages/ContactUsPage"));
const FounderPage = React.lazy(() => import("./pages/FounderPage"));
const CataloguePage = React.lazy(() => import("./pages/CataloguePage"));
const PrivacyPolicyPage = React.lazy(() => import("./pages/PrivacyPolicyPage"));
const TermsAndConditionsPage = React.lazy(() => import("./pages/TermsAndConditionsPage"));
const FooterComponent = React.lazy(() => import("./components/FooterComponent"));
const HeadlineComponent = React.lazy(() => import('./components/LandingPageComponents/HeadlineComponent'))


const loading = () => (
  <section className='loader_container'>
    <span className="loader"></span>
  </section>
)

AOS.init();

const App = (props) => {
  return (
    <div className="app">
      <HeadlineComponent />
      <NavbarComponent />
      <Router>
        <React.Suspense fallback={loading()}>
          <Routes>
            <Route path='/' element={<LandingPage  {...props} />} />
            <Route path='/contact-us' element={<ContactUsPage {...props} />} />
            <Route path='/about-us' element={<FounderPage {...props} />} />
            <Route path='/catalogue' element={<CataloguePage {...props} />} />
            <Route path='/privacy-policy' element={<PrivacyPolicyPage {...props} />} />
            <Route path='/tnc' element={<TermsAndConditionsPage {...props} />} />
            <Route path='*' element={<Page404 />} />
          </Routes>
        </React.Suspense>
      </Router>
      <FooterComponent />
    </div>
  );
}

export default App;
